import {
  Button,
  Container,
  TextField,
  Switch,
  FormControlLabel,
  Alert,
  Grid,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../actions/userAction"; // Import the updateUser action
import AddressCard from "./AddressCard";
import AddAddressCard from "./AddAddressCard";

const ProfilePage = () => {
  const userData = useSelector((state) => state.user.user);
  const [editedUserData, setEditedUserData] = useState({ ...userData });
  const addresses = useSelector((state) => state.address && state.address.address && state.address.address.data); // Adapt this line to your Redux state
  // console.log ("addresses", addresses);

  const [isEditing, setIsEditing] = useState(false);
  const [alert, setAlert] = useState({ show: false, type: "", message: "" });

  // const [avatar, setAvatar] = useState();
  // const [avatarPreview, setAvatarPreview] = useState("/Profile.png");
  const dispatch = useDispatch();
  let alertTimeout = null;

  useEffect(() => {
    setEditedUserData({ ...userData });
    dispatch(updateUser())
    // Cleanup to clear the timeout when the component unmounts or userData updates
    return () => {
      if (alertTimeout) {
        clearTimeout(alertTimeout);
      }
    };
  }, [userData,dispatch]);

  //   const handleChange = (e) => {
  //     setEditedUserData({ ...editedUserData, [e.target.name]: e.target.value });
  //   };



  const handleChange = (e) => {
    if (e.target.name === "image") {
      // Handle file input
      setEditedUserData({ ...editedUserData, image: e.target.files[0] });
    } else {
      setEditedUserData({ ...editedUserData, [e.target.name]: e.target.value });
    }
  };

  const setAlertWithTimeout = (type, message) => {
    setAlert({ show: true, type, message });
    alertTimeout = setTimeout(() => {
      setAlert({ show: false, type: "", message: "" });
    }, 5000);
  };

  const handleUpdateProfile = () => {
    const fieldsToUpdate = Object.keys(editedUserData).reduce((acc, key) => {
      if (editedUserData[key] !== userData[key]) {
        acc[key] = editedUserData[key];
      }
      return acc;
    }, {});

    if (Object.keys(fieldsToUpdate).length > 0) {
      dispatch(updateUser(fieldsToUpdate))
        .then(() => {
          setAlertWithTimeout("success", "Profile updated successfully!");
        })
        .catch((error) => {
          setAlertWithTimeout("error", `Update failed: ${error.message}`);
        });
    }
  };

  return (
    <Container
      style={{
        backgroundColor: "#fdf5e6",
        padding: "2rem",
        minHeight: "55rem",

      }}
    >
      <div>
        <h1>My Profile</h1>
        <FormControlLabel
          control={
            <Switch
              checked={isEditing}
              onChange={() => setIsEditing(!isEditing)}
              color="primary"
            />
          }
          label={isEditing ? "Discard Edit" : "Edit Profile"}
        />
        {alert.show && <Alert severity={alert.type}>{alert.message}</Alert>}
        {userData ? (
          // <div>
          //   <TextField
          //    style={{paddingLeft:'2rem'}}
          //   variant="standard"
          //     name="username"
          //     label="Username"
          //     value={editedUserData.username}
          //     onChange={handleChange}
          //     margin="normal"
          //     disabled={!isEditing}
          //   />
          //   <TextField
          //    style={{paddingLeft:'2rem'}}
          //   variant="standard"
          //     name="email"
          //     label="Email"
          //     value={editedUserData.email}
          //     onChange={handleChange}
          //     margin="normal"
          //     disabled={!isEditing}
          //   />
          //   <TextField
          //    style={{paddingLeft:'2rem'}}
          //   variant="standard"
          //     name="mobile"
          //     label="Mobile"
          //     value={editedUserData.mobile}
          //     onChange={handleChange}
          //     margin="normal"
          //     disabled={!isEditing}
          //   />           
          // </div>
          <Grid container spacing={2} alignItems="center" >

          <Grid item xs={12} sm={4}>
            <TextField
              variant="standard"
              fullWidth
              name="username"
              label="Username"
              value={editedUserData.username}
              onChange={handleChange}
              margin="normal"
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="standard"
              fullWidth
              name="email"
              label="Email"
              value={editedUserData.email}
              onChange={handleChange}
              margin="normal"
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="standard"
              fullWidth
              name="mobile"
              label="Mobile"
              value={editedUserData.mobile}
              onChange={handleChange}
              margin="normal"
              // disabled={!isEditing}
              disabled={true}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
            
              variant="outlined"
              fullWidth
              name="wallet"
              label="Wallet balance available"
              value={userData.balance}
              onChange={handleChange}
              margin="normal"
              // disabled={!isEditing}
              disabled={true}
            />
          </Grid>
        </Grid>
        ) : (
          <p>Loading user data...</p>
        )}

        {isEditing && (
          <Button variant="contained" onClick={handleUpdateProfile}>
            Save Changes
          </Button>
          // resetpassword
        )}
      </div>
      <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <AddAddressCard/>
              {addresses &&
                addresses.map((address) => (
                  <AddressCard key={address.id} address={address} />
                ))}
            </div>

            <Button variant="contained" href="/resetpassword" onClick={handleUpdateProfile}>
            Reset Password
          </Button>
    </Container>
  );
};

export default ProfilePage;
