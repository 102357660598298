import AddAddressCard from "../User/AddAddressCard";
import AddressCard from "../User/AddressCard";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckoutProductCard from "./checkoutProductCard";
import { useNavigate } from "react-router-dom";
import LinearLoader from "../layout/Loader/LinearLoader";
import { getAddress, loadUser, updateUser } from "../../actions/userAction";
import { BASE_URL, SECRET_KEY } from "../../App";
import React, { useEffect, useRef, useState } from "react";
import ResponsiveDialog from "../layout/Dialog";
import CryptoJS from "crypto-js";
import store from "../../store";

const Checkout = () => {
  const secretKey = SECRET_KEY;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);
  const scroll = (scrollOffset) => {
    scrollContainerRef.current.scrollBy({
      left: scrollOffset,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    store.dispatch(loadUser());
    dispatch(updateUser());
    dispatch(loadUser());
    dispatch(getAddress());
    // dispatch(fetchOrders());
  }, [dispatch]);

  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  };

  const decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const originalData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(originalData);
  };

  function convertToIST(dateString) {
    const date = new Date(dateString);
    const utcDate = date.getTime() + date.getTimezoneOffset() * 60000;
    const istDate = new Date(utcDate + 3600000 * 5.5);
    const formattedDate = istDate.toLocaleString("en-IN", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata",
    });

    return formattedDate;
  }

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [walletAmount, setWalletAmount] = useState(0);
  const [usedWalletAmount, SetUsedWalletAmount] = useState(0);
  const [couponDiscount, SetCouponDiscount] = useState(0);
  const [promoCodes, setPromoCodes] = useState([]); // State to store promo codes
  const [fetchError, setFetchError] = useState(""); // State to store any fetch errors
  const [validatePromoCodeResponse, SetvalidatePromoCodeResponse] =
    useState("");
  const [valid_codes, SetValid_code] = useState([]);

  // Payment Specific States
  const [payment_id, setPayment_id] = useState(""); // Loading state
  const [PaymentStatus, SetPaymentStatus] = useState("");
  const [PaymentToken, SetPaymentToken] = useState("");
  const [merchantTransactionId, SetMerchantTransactionId] = useState("");
  const [transactionId, SettransactionId] = useState("");
  const [merchantId, SetMerchantId] = useState("");
  const [token, setToken] = useState("");
  const [loadingPayment, setLoadingPayment] = useState(false);

  // OrderObject
  const [createOrderObj, SetCreateOrderObj] = useState("");
  const [orderObj, SetOrderObj] = useState("");

  const userId = useSelector((state) => state.user?.user?.id);
  const userName = useSelector((state) => state.user?.user?.username);
  const userPhone = useSelector((state) => state.user?.user?.mobile);
  const userMail = useSelector((state) => state.user?.user?.email);
  const addresses = useSelector((state) => state.address?.address?.data);
  const { error, loading, cartData } = useSelector((state) => state.cart);
  const maxWalletAmount = parseFloat(
    useSelector((state) => state.user?.user?.balance)
  );
  const quantities = cartData?.data?.data.map((item) => parseFloat(item.qty));
  const productVariantIds = cartData?.data?.data.map(
    (item) => item?.product_variant_id
  );

  let cartList = cartData ? cartData?.data?.data : [];
  const subtotal = parseFloat(
    useSelector((state) => state?.cart?.cartData?.data?.sub_total)
  );
  // console.log("subtotal", subtotal);
  let deliveryCharges = 0;
  if (subtotal < 100) {
    deliveryCharges = 100;
  } else if (subtotal < 500) {
    deliveryCharges = 150;
  } else if (subtotal < 999) {
    deliveryCharges = 200;
  }

  const removeInvalidPromoCodes = async (value, index, array) => {
    var valid_promo_codes = [];

    // console.log("value", value.id);

    const response = await axios({
      method: "post",
      url: BASE_URL + "/app/middleware/v1/validatepromocode",
      headers: { "Content-Type": "application/json" },
      data: {
        userid: userId,
        promo_code: value.promo_code,
        final_total: 99999,
      },
    });

    // console.log("response.error", response?.data?.error);

    if (!response?.data?.error) {
      valid_promo_codes.push(value);
      SetValid_code((valid_codes) => [...valid_codes, value]);
    }
    // console.log("valid_promo_codes.length", valid_promo_codes);
  };

  useEffect(() => {
    promoCodes.forEach(removeInvalidPromoCodes);
  }, [promoCodes]);

  const fetchPromoCodes = async () => {
    if (userId !== undefined) {
      try {
        const response = await axios({
          method: "post",
          url: BASE_URL + "/app/middleware/v1/promocode",
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
          data: { userid: userId },
        });
        if (response.data && !response.data.error) {
          setPromoCodes(response.data.promo_codes);
          // console.log("promoCodes", response.data.promo_codes);
        } else {
          setFetchError("Failed to fetch promo codes");
        }
      } catch (error) {
        setFetchError(
          error.message || "An error occurred while fetching promo codes"
        );
      }
    }
  };

  useEffect(() => {
    dispatch(updateUser());
    fetchPromoCodes();
  }, [dispatch, userId]);

  const ValidatePromoCodes = async (code, total) => {
    try {
      const response = await axios({
        method: "post",
        url: BASE_URL + "/app/middleware/v1/validatepromocode",
        headers: { "Content-Type": "application/json" },
        data: { userid: userId, promo_code: code, final_total: total },
      });

      if (response.data && !response.data.error) {
        SetvalidatePromoCodeResponse(response.data);
        SetCouponDiscount(response.data.data[0].final_discount);
      } else {
        SetvalidatePromoCodeResponse(response.data);
      }
    } catch (error) {
      SetvalidatePromoCodeResponse(
        error.message || "An error occurred while fetching promo codes"
      );
    }
  };

  const [activePromo, setActivePromo] = useState("");
  const [couponValid, setCouponValid] = useState(false);
  const applyCouponCode = async (e, subtotal) => {
    const selectedPromoCode = valid_codes.find(
      (promo) => promo?.id === e.toString()
    );
    await ValidatePromoCodes(selectedPromoCode.promo_code, subtotal);
    setActivePromo(selectedPromoCode);
    setCouponValid(true);
  };

  useEffect(() => {
    // console.log("usedWalletAmount", usedWalletAmount);
  }, [usedWalletAmount]);

  const [grandTotal, SetGrandTotal] = useState(0);
  const [amountToPay, SetAmountToPay] = useState(0);
  useEffect(() => {
    SetGrandTotal(subtotal + deliveryCharges - couponDiscount);
  }, [subtotal, deliveryCharges, couponDiscount]);

  useEffect(() => {
    SetAmountToPay(
      parseFloat(subtotal) +
        parseFloat(deliveryCharges) -
        parseFloat(couponDiscount) -
        parseFloat(usedWalletAmount)
    );
  }, [subtotal, deliveryCharges, couponDiscount, usedWalletAmount]);

  useEffect(() => {
    const totalCharge = subtotal + deliveryCharges - couponDiscount;
    const newUsedWalletAmount = Math.min(totalCharge, maxWalletAmount);

    // Adjust usedWalletAmount if it causes the amountToPay to be negative
    if (totalCharge < usedWalletAmount) {
      SetUsedWalletAmount(newUsedWalletAmount);
      setWalletAmount(newUsedWalletAmount);
    }
  }, [
    subtotal,
    deliveryCharges,
    couponDiscount,
    maxWalletAmount,
    usedWalletAmount,
  ]);

  // Update the wallet amount input when usedWalletAmount changes
  useEffect(() => {
    setWalletAmount(usedWalletAmount);
  }, [usedWalletAmount]);

  const [processingOrder, SetProcessingOrder] = useState(false);

  const processingOrderRef = useRef(false);

  const WaitingForPaymentRef = useRef(false);

  const merchantIdRef = useRef("");
  const merchantTransactionIdRef = useRef("");
  const originalTransactionIdRef = useRef("");
  const amountRef = useRef("");

  const getToken = () => {
    setLoadingPayment(true);
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL + "/app/payment/v1/get-token")
        .then((res) => {
          setToken(res.data); // Assume res.data is the token
          setLoadingPayment(false);
          resolve(res.data); // Resolve the promise with the token
        })
        .catch((error) => {
          setLoadingPayment(false);
          console.error(error);
          reject(error); // Reject the promise if there's an error
        });
    });
  };

  const handlePayment = async () => {
    WaitingForPaymentRef.current = true;
    SetCheckoutButtonText("Processing...");

    if (createOrderObj.final_total === 0) {
      SetProcessingOrder(true);
      const createOrder = await axios.post(
        BASE_URL + "/app/middleware/v1/createorder",
        createOrderObj
      );

      // const createScanner = await axios.post(
      //   BASE_URL + "/app/middleware/v1/createscanner",
      //   {
      //     "orderid":createOrder?.data?.order_id.toString(),
      //     "status":"success"
      //   }
      // );
      // console.log("Some error occ", createOrder.error);
      SetPaymentStatus("PAYMENT_SUCCESS");

      if (createOrder.data.error) {
        // console.log("Some error occ", createOrder.data.error);
        navigate(`/orderconfirmation/error`);
      } else {
        navigate(
          `/orderconfirmation/${createOrder?.data?.order_item_data[0]?.order_id}`
        );
      }
    }

    try {
      if (createOrderObj.final_total !== 0) {
        // const token = await getToken(); // Wait for getToken to complete
        const formData = {
          amount: amountToPay,
          purpose: `Order by ${selectedAddress?.mobile}`,
          phone: userPhone,
          email: userMail,
          name: userName,
        };

        axios
          .post(BASE_URL + "/app/payment/v1/payment", {
            name: userId,
            amount: amountToPay,
            number: selectedAddress?.mobile,
            MUID: "MUID" + Date.now(),
            transactionId: "T" + Date.now(),
          })
          .then((res) => {
            SetMerchantTransactionId(
              res.data.message.data.merchantTransactionId
            );
            SetMerchantId(res.data.message.data.merchantId);
            merchantIdRef.current = res.data.message.data.merchantId;
            merchantTransactionIdRef.current =
              res.data.message.data.merchantTransactionId;

            setTimeout(() => {}, 1500);
            console.log(
              "res.data.data.instrumentResponse.redirectInfo.url",
              res.data.message.data.instrumentResponse.redirectInfo.url
            );
            const url =
              res?.data?.message?.data?.instrumentResponse?.redirectInfo?.url;

            if (url) {
              window.open(url, "_blank");
            } else {
              // Handle the missing URL scenario, maybe set an error state or log an error
            }
          })
          .catch((error) => {
            console.error(error);
          });

        // SetPaymentToken(token);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [checkoutButtonText, SetCheckoutButtonText] = useState("Loading...");

  useEffect(() => {
    if (amountToPay == 0) {
      SetCheckoutButtonText("Place Order");
    } else {
      SetCheckoutButtonText("Make Payment and Place Order");
    }
  }, [amountToPay]);

  useEffect(() => {
    const commonOrderObj = {
      user_id: userId,
      product_variant_id: productVariantIds,
      address_id: selectedAddress?.id,
      mobile: selectedAddress?.mobile,
      quantity: quantities,
      delivery_charge: deliveryCharges,
      tax_amount: 0,
      tax_percentage: 0,
      delivery_time: "Today - Evening (4:00pm to 7:00pm)",
      payment_method: "PhonePe",
      is_wallet_used: usedWalletAmount > 0 ? 1 : 0,
      wallet_balance_used: parseFloat(usedWalletAmount.toFixed(2)),
      total: subtotal,
      final_total: amountToPay,
      order_note: payment_id || selectedAddress?.mobile,
    };

    if (orderObj?.promocode) {
      commonOrderObj.promo_code = activePromo.promo_code;
    }
    SetCreateOrderObj(commonOrderObj);
  }, [
    userId,
    // quantities,
    grandTotal,
    // productVariantIds,
    usedWalletAmount,
    subtotal,
    amountToPay,
    activePromo,
    deliveryCharges,
    selectedAddress,
    maxWalletAmount,
  ]);

  const processOrder = async () => {
    if (
      (PaymentStatus === "Completed" || PaymentStatus === "PAYMENT_SUCCESS") &&
      createOrderObj.final_total !== 0 &&
      !processingOrderRef.current
    ) {
      processingOrderRef.current = true;
      SetProcessingOrder(true);
      try {
        // throw new Error('Input must be a number');

        const createOrder = await axios.post(
          BASE_URL + "/app/middleware/v1/createorder",
          createOrderObj
        );

        // const createScanner = await axios.post(
        //   BASE_URL + "/app/middleware/v1/createscanner",
        //   {
        //     "orderid":createOrder?.data?.order_id.toString(),
        //     "status":"success"
        //   }
        // );
        // console.log("createOrder.data.error",createOrder.data)

        if (createOrder.data.error) {
          // console.log("Some error occurred", createOrder.data);
          const refundresponse = await axios.post(
            BASE_URL + "/app/payment/v1/create-refund",
            {
              merchantUserId: userId,
              originalTransactionId: originalTransactionIdRef.current,
              merchantTransactionId: merchantTransactionIdRef.current,
              amount: amountToPay,
              // callbackUrl: "https://webhook.site/callback-url"
            }
          );

          navigate(`/orderconfirmation/error`);
          // navigate(`/orderconfirmation/error?${refundresponse?.refund?.id}?refundamount=${refundresponse?.refund?.refund_amount}?payment_id=${refundresponse.refund.payment_id}`);

          return; // Early return to avoid navigating to the success page
        }

        navigate(
          `/orderconfirmation/${createOrder?.data?.order_item_data[0]?.order_id}`
        );
      } catch (error) {
        console.error("Error in processOrder:", error);

        // const refundresponse = await axios.post(
        //   BASE_URL + "/app/payment/v1/create-refund",
        //   {
        //     type: "TNR",
        //     transaction_id: "transaction_id", // Ensure this is the correct transaction ID
        //     error_message: error.message,
        //     refund_amount: createOrderObj.final_total,
        //     payment_id: payment_id, // Make sure payment_id is defined and correct
        //     access_token: PaymentToken, // Ensure PaymentToken is the correct token
        //   }
        // );
        const refundresponse = await axios.post(
          BASE_URL + "/app/payment/v1/phonepe-refund",
          {
            merchantUserId: userId,
            originalTransactionId: originalTransactionIdRef.current,
            merchantTransactionId: merchantTransactionIdRef.current,
            amount: amountToPay,
            // callbackUrl: "https://webhook.site/callback-url"
          }
        );

        navigate(`/orderconfirmation/error`);
      }
    }
  };

  useEffect(() => {
    const checkPaymentStatus = async () => {
      if (merchantTransactionId) {
        try {
          const response = await axios.post(
            BASE_URL + `/app/payment/v1/status`,
            {
              transactionId: merchantTransactionId,
              merchantId: merchantId,
            }
          );

          SetPaymentStatus(response?.data?.message?.code);
          console.log("response?.data?.code", response?.data?.message?.code);

          SettransactionId(response?.data?.message?.data?.transactionId);

          originalTransactionIdRef.current =
            response?.data?.message?.data?.transactionId;

          // console.log("status response", response.data);
          const status = response?.data?.status;
        } catch (error) {
          console.error("Error checking payment status:", error);
        }

        processOrder();
      }
    };

    var timeout = 2000;
    if (PaymentStatus === "PAYMENT_SUCCESS") {
      timeout = 100000;
    }
    const intervalId = setInterval(checkPaymentStatus, timeout);
    checkPaymentStatus();

    return () => clearInterval(intervalId);
  }, [PaymentStatus, merchantTransactionId]);

  useEffect(() => {
    if (PaymentStatus === "PAYMENT_SUCCESS") {
      SetCheckoutButtonText("Payment Recieved");
    }
  }, [PaymentStatus]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (processingOrderRef.current) {
      setOpen(true);
    }
  }, [processingOrderRef]);

  console.log("grandTotal", grandTotal);
  return (
    <>
      {processingOrderRef.current && (
        <ResponsiveDialog
          handleClose={handleClose}
          handleClickOpen={handleClickOpen}
          open={true}
          message="Payment Recieved, Please do not close or refresh this page"
          title="Processing Order"
        />
      )}

      {(PaymentStatus === "BAD_REQUEST" ||
        PaymentStatus === "AUTHORIZATION_FAILED" ||
        PaymentStatus === "INTERNAL_SERVER_ERROR" ||
        PaymentStatus === "TRANSACTION_NOT_FOUND" ||
        PaymentStatus === "PAYMENT_ERROR" ||
        PaymentStatus === "PAYMENT_PENDING" ||
        PaymentStatus === "PAYMENT_DECLINED" ||
        PaymentStatus === "TIMED_OUT") && (
        <ResponsiveDialog
          handleClose={handleClose}
          handleClickOpen={handleClickOpen}
          open={true}
          message="Please do not close or refresh this page"
          title="Waiting for Payment"
        />
      )}

      <Container style={{ paddingBottom: "2rem" }}>
        {isNaN(grandTotal) ? (
          <LinearLoader />
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ flexGrow: 1, textAlign: "center", paddingTop: "2rem" }}
              >
                <Typography variant="h6">Select Address</Typography>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                ref={scrollContainerRef}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  overflowX: "auto",
                  flexWrap: "nowrap",
                }}
              >
                <AddAddressCard />
                {addresses &&
                  addresses.map((address) => (
                    <AddressCard
                      key={address?.id}
                      address={address}
                      isSelected={address?.id === selectedAddress?.id}
                      onSelect={() => setSelectedAddress(address)}
                    />
                  ))}
              </div>

              <IconButton onClick={() => scroll(300)}>
                <ArrowForwardIosIcon />
              </IconButton>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                overflow: "scroll",
              }}
            >
              {cartList &&
                cartList &&
                cartList.map((item) => (
                  <div style={{ padding: "8px" }}>
                    <CheckoutProductCard key={item?.id} product={item} />
                  </div>
                ))}
            </div>

            <div>
              <Typography variant="h5">Available Promo Codes</Typography>
              {validatePromoCodeResponse?.error && (
                <Alert
                  severity={
                    validatePromoCodeResponse.error ? "error" : "success"
                  }
                >
                  {validatePromoCodeResponse?.error
                    ? validatePromoCodeResponse.message
                    : "Coupon Applied"}
                </Alert>
              )}
              {!validatePromoCodeResponse?.error &&
                validatePromoCodeResponse.message && (
                  <Alert
                    severity={
                      validatePromoCodeResponse.error ? "error" : "success"
                    }
                  >
                    {validatePromoCodeResponse?.error
                      ? validatePromoCodeResponse.message
                      : `${validatePromoCodeResponse.data[0].promo_code} - Coupon Applied`}
                  </Alert>
                )}
              {valid_codes.map((promo) => (
                <div key={promo?.id} style={{ padding: "1rem" }}>
                  <Button
                    variant="contained"
                    onClick={() => applyCouponCode(promo?.id, subtotal)}
                  >
                    {promo.promo_code}
                  </Button>
                  <Typography style={{ fontSize: "13px", paddingTop: "1rem" }}>
                    {" "}
                    {promo.message}
                  </Typography>
                  <Typography style={{ fontSize: "12px" }}>
                    Minimum order amount : {promo.min_order_amt}, Maximum
                    discount amount : {promo.max_discount_amt}
                  </Typography>
                  <Typography style={{ fontSize: "12px" }}>
                    Offer ends on {convertToIST(promo.end_date)}
                  </Typography>
                </div>
              ))}
            </div>

            <Typography>Available Wallet Amount : {maxWalletAmount}</Typography>
            <Box sx={{ display: "flex", gap: 2, my: 2 }}>
              <TextField
                style={{ width: "100%" }}
                label="Wallet Amount"
                type="number"
                variant="outlined"
                value={walletAmount}
                onChange={(e) => {
                  const newValue = Number(e.target.value);
                  const maxInputAmount = Math.min(maxWalletAmount, grandTotal);
                  setWalletAmount(Math.min(newValue, maxInputAmount));
                }}
              />
              <Button
                variant="text"
                onClick={() => {
                  SetUsedWalletAmount(walletAmount);
                }}
                style={{ width: "18rem" }}
              >
                Enter amount to use
              </Button>
            </Box>

            <Divider style={{ width: "100%" }}>
              <Chip label="Order Summary" size="small" />
            </Divider>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                mb: 1,
              }}
            >
              <Typography>Item(s) Subtotal:</Typography>
              {subtotal && <Typography>₹{subtotal.toFixed(2)}</Typography>}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                mb: 1,
              }}
            >
              <Typography>Shipping:</Typography>
              {deliveryCharges && (
                <Typography>₹{deliveryCharges.toFixed(2)}</Typography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                mb: 1,
              }}
            >
              <Typography>Coupon Discount:</Typography>
              {couponDiscount && <Typography>₹{couponDiscount}</Typography>}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                mb: 1,
              }}
            >
              <Typography>Wallet Amount Used:</Typography>
              <Typography>₹{usedWalletAmount.toFixed(2)}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                // borderTop: "1px solid black",
                pt: 1,
              }}
            >
              <Typography variant="h5">Grand Total:</Typography>
              <Typography variant="h5">₹{grandTotal}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                borderTop: "1px solid black",
                pt: 1,
              }}
            >
              <Typography variant="h5">To Pay:</Typography>
              <Typography variant="h5">₹{amountToPay}</Typography>
            </Box>

            {!selectedAddress?.id && (
              <div>
                <Typography style={{ color: "red" }}>
                  Please select address
                </Typography>
              </div>
            )}

            <Button
              disabled={!selectedAddress?.id || WaitingForPaymentRef.current}
              variant="contained"
              onClick={() => handlePayment()}
            >
              {checkoutButtonText}
            </Button>
          </div>
        )}
      </Container>
    </>
  );
};

export default Checkout;
